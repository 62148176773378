<template>
  <div v-if="performance" style="white-space: break-spaces;" class="mb-4">
    {{ performance }}
  </div>
  <div v-if="roles.length > 0" class="flex flex-wrap justify-content-start gap-1">
    <span v-for="(role) in roles.sort()" :key="role">
      <Chip class="bg-blue-50" :label="role" />
    </span>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted } from "vue";
import db from '@/firebase/firestore.js'
import { doc, getDoc } from "firebase/firestore"

const props = defineProps({ doc: Object })
const roles = ref([]);
const performance = ref();
const isLoading = ref(false)
const error = ref()

const lengthLimit = 100

const getRoles = async () => {
  isLoading.value = true
  try {
    const memberRef = doc(db, "projects", props.doc.id, "members", "hirofukami")
    const memberDoc = await getDoc(memberRef)

    if (memberDoc.exists()) {
      if (memberDoc.data().performance) {
        if (memberDoc.data().performance.length > lengthLimit) {
          performance.value = memberDoc.data().performance.substring(0, lengthLimit) + "..."
        } else {
          performance.value = memberDoc.data().performance
        }
      }
      roles.value = memberDoc.data().roles
      console.log("roles: " , roles.value)
    } else {
      console.log("No such document!")
    }
  } catch (e) {
      error.value = e
  } finally {
      isLoading.value = false
  }
}

onMounted(() => {
  getRoles()
})
</script>
