<template>
  <Card class='my-2 text-left' @click="visible = true">
    <template #title>
      {{ props.doc.data().title }}
    </template>
    <template v-if="range(props.doc.data())" #subtitle>
      {{ range(props.doc.data()) }}
    </template>
    <template #content>
      <Roles
        :doc="props.doc"
      ></Roles>
    </template>
  </Card>
  <Dialog v-model:visible="visible" modal header="プロジェクト詳細"
    :style="{ width: '70vw' }"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <ProjectDetail
      :doc="props.doc"
    ></ProjectDetail>
  </Dialog>
</template>

<script setup>
import { ref, defineProps } from "vue";
import helpers from "@/helpers"
import Roles from "./Roles"
import ProjectDetail from "./ProjectDetail"

const props = defineProps({ doc: Object })
const visible = ref(false)

const range = (doc) => {
  return helpers.rangeStartEnd(doc)
}
</script>
