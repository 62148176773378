<template>
  <header>
    <Menubar/>
  </header>

  <div class="grid">
    <div class="col-12 md:col-10 md:col-offset-1">
      <router-view></router-view>
    </div>
  </div>

  <footer>
    <div class="text-center my-6 text-500">© Players1st inc. All rights reserved.</div>
  </footer>
</template>

<script>
import Menubar from './components/Menubar.vue'

export default {
  name: 'App',
  components: {
    Menubar,
  }
}
</script>

<style>
#app {
  font-family: -apple-system, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 80px;
}
</style>
