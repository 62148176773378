<template>
  <div class="md:p-3">

    <div class="mb-5">
      依頼・相談したい内容をお送りください。<br>
      メールアドレス宛にご連絡いたします。
    </div>

    <form
      action="https://formspree.io/f/moqzplgd"
      method="POST"
    >
      <input type="hidden" name="type" value="仕事依頼・相談 フォームより">

      <div class="field">
        <InputText type="name" name="name" required="" placeholder="氏名" class="w-full" />
      </div>

      <div class="field">
        <InputText type="email" name="email" required="" placeholder="メールアドレス" class="w-full" />
      </div>

      <div class="field">
        <InputText type="company" name="company" required="" placeholder="会社名" class="w-full" />
      </div>

      <div class="field">
        <Textarea name="message" required="" rows="8" placeholder="依頼・相談内容を入力ください" class="w-full" />
      </div>

      <Button type="submit" label="送信" severity="primary" />
    </form>

  </div>
</template>

<script setup>

</script>
