<template>
  <div class="text-xl text-left">{{ position.data().name }}</div>

  <div class="grid">
    <template v-for="(doc, index) in projects" :key="doc.data().id">
      <div v-if="index < 3 || isMore" class="md:col-4">
        <Project
         :doc="doc"
         ></Project>
      </div>
    </template>
  </div>

  <div v-if="projects.length > 3 && !isMore" class="text-left">
    <Button link label="More" icon="pi pi-chevron-down" iconPos="right" @click="isMore = true"/>
  </div>
</template>

<script>
import db from '@/firebase/firestore.js'
import { collection, query, orderBy, onSnapshot, getDoc } from "firebase/firestore"
import helpers from "@/helpers"
import Project from "./Project"

export default {
  name: 'Projects',
  props: {
    position: {
      type: Object
    },
    company: {
      type: Object
    },
  },
  data: () => ({
   projects: [],
   isMore: false,
  }),
  components: {
    Project
  },

  async created() {
    const q = query(
          collection(db, "users", "hirofukami", "companies", this.company.id, "positions", this.position.id, "projects"),
          orderBy('start', 'desc'),
          orderBy('ref')
        )

    onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        const pjDoc = await getDoc(change.doc.data().ref)
        this.projects.push(pjDoc)
      })
      console.log("projects data:", this.projects)
    })
  },
  methods: {
    range: function(doc) {
      return helpers.rangeStartEnd(doc)
    }
  }
}
</script>
