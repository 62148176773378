<template>
  <Card>
    <template #title>
      <div class="flex align-items-center justify-content-start">
        <Avatar
          :image=image
          class="flex align-items-center justify-content-center"
          shape="circle"
          style="height: 50px; width: 50px;"
        />
        <div class="flex align-items-center justify-content-center text-lg ml-2 mr-5">
          {{ last_name }} {{ first_name }}
        </div>
      </div>
    </template>
    <template #content>
      <div v-if="props.doc.data().roles.length > 0" class="flex flex-wrap justify-content-start gap-1">
        <span v-for="(role) in props.doc.data().roles.sort()" :key="role">
          <Chip class="bg-blue-50" :label="role" />
        </span>
      </div>
      <div class="mt-3" style="white-space: break-spaces;">
        {{ props.doc.data().performance }}
      </div>
    </template>
  </Card>
</template>

<script setup>
import { ref, defineProps, onMounted } from "vue";
import { getDoc } from "firebase/firestore"
const props = defineProps({ doc: Object })

const last_name = ref()
const first_name = ref()
const image = ref()

const getUser = async (ref) => {
  const userDocSnap = await getDoc(ref)

  if (userDocSnap.exists()) {
    const doc = userDocSnap.data()
    first_name.value = doc.first_name
    last_name.value = doc.last_name
    image.value = doc.image
    console.log("Document data:", userDocSnap.data())
  } else {
    console.log("No such document!")
  }
}

onMounted(() => {
  getUser(props.doc.data().ref)
})
</script>
