<template>
  <div class="flex align-items-center justify-content-center my-6">
    <Avatar
      :image=image
      class="flex align-items-center justify-content-center"
      shape="circle"
      style="height: 100px; width: 100px;"
    />
    <div class="flex align-items-center justify-content-center text-4xl font-semibold mx-3">
      {{ last_name }} {{ first_name }}
    </div>
  </div>

  <div class="my-6 text-center">
    <Button
      label="仕事依頼・相談をする" icon="pi pi-envelope" rounded severity="info"
      @click="visible = true"
    />
  </div>

  <Dialog v-model:visible="visible" modal header="コンタクト"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <FormContact />
  </Dialog>

  <div class="description grid my-3">
    <div class="col-12 md:col-10 md:col-offset-1">
      <div class="content">
        アメリカ法人含め3社を立ち上げ、新規プロダクトを多数リリース。プロダクトマネジメントをしつつ自らもエンジニアとして開発に携わる。
      </div>
      <div class="content">
        ネットワーク・インフラからWebアプリ、スマホアプリ開発まで幅広く対応する。
      </div>
      <div class="conte">
        AWSの新機能をいち早く試した実績が評価されAWS入門本を共著。最近の著書は「ひとりスタートアップ」
      </div>
    </div>
  </div>

  <div class="m-2 grid">
    <div class="md:col-10 md:col-offset-2">
      <div class="text-3xl text-left font-semibold">Summary</div>
      <div class="my-2 text-lg">
        <div v-for="doc in companies" :key="doc.id" class="grid">
          <div v-if="doc.data()" class="col-4 text-left" >
            {{ range(doc.data()) }}
          </div>
          <div class="col text-left">{{ doc.id }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="projects">
    <div v-for="doc in companies" :key="doc.id" class="my-6">
      <Position
        :company="doc"
      ></Position>
    </div>
  </div>
</template>

<script>
import db from '@/firebase/firestore.js'
import { doc, getDoc, collection, getDocs, query, orderBy } from "firebase/firestore"
import Position from "./Position"
import FormContact from "./FormContact"
import helpers from "@/helpers"

export default {
  name: 'Profile',
  data: () => ({
   first_name: '',
   last_name: '',
   image: '',
   companies: [],
   visible: false
  }),
  components: {
    Position,
    FormContact
  },

  async created() {
    const userDocRef = doc(db, "users", "hirofukami")
    const userDocSnap = await getDoc(userDocRef)

    if (userDocSnap.exists()) {
      const doc = userDocSnap.data()
      this.first_name = doc.first_name
      this.last_name = doc.last_name
      this.image = doc.image
      console.log("Document data:", userDocSnap.data())
    } else {
      console.log("No such document!")
    }

    const companiesSnap = await
      getDocs(
        query(
          collection(db, "users", "hirofukami", "companies"),
          orderBy('start', 'desc')
        )
      )

    companiesSnap.forEach((doc) => {
      this.companies.push(doc)
      console.log("Document data:", doc)
    })
  },

  methods: {
    range: function(doc) {
      return helpers.rangeEndStart(doc)
    }
  }
}
</script>

<style>

</style>
