<template>
  <div class="section">
    <div class="title text-center text-5xl">あなたの歩んできた道のりが<br>最大の営業ツールになります</div>
    <div class="subtitle text-center">オープンな職歴であなたのビジネスをPRしましょう</div>
  </div>

  <div class="section bg-yellow-50">
    <div class="title text-center">経験豊富なITエンジニア・専門家の課題を解決します</div>
    <div class="grid">
      <div class="col-12 md:col-6">
        <Card>
          <template #title>常に自転車操業、案件獲得が不安定</template>
          <template #content>案件獲得が仲介業者、派遣会社経由になっている</template>
        </Card>
      </div>
      <div class="col-12 md:col-6">
        <Card>
          <template #title>人材派遣会社への依存</template>
          <template #content>ITへの理解不足、高い手数料、準業務委託の契約で個人しか稼働できない</template>
        </Card>
      </div>
      <div class="col-12 md:col-6">
        <Card>
          <template #title>仲介業者のパフォーマンスの悪さ</template>
          <template #content>受注率が低い、受注前の面談など無駄な稼働を強いられる、値下げを圧力</template>
        </Card>
      </div>
      <div class="col-12 md:col-6">
        <Card>
          <template #title>実績が営業に活かせていない</template>
          <template #content>実績を正しくPRする手段が乏しい</template>
        </Card>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="title text-center">サービスの特徴</div>
    <div class="grid">
      <div class="col-12 md:col-6">
        <Card>
          <template #title>
            <div class="text-primary text-center">
              <i class="fa-solid fa-globe text-6xl mb-3"></i>
              <div>オープンなプロフィール</div>
            </div>
          </template>
          <template #content>職歴を登録して公開できます。あなただけの職歴プロフィールページが作れます。</template>
        </Card>
      </div>

      <div class="col-12 md:col-6">
        <Card>
          <template #title>
            <div class="text-green-500 text-center">
              <i class="fa-solid fa-sheet-plastic text-6xl mb-3"></i>
              <div>プロジェクトベースの職歴</div>
            </div>
          </template>
          <template #content>案件をプロジェクトとして登録できます。利用したマネジメント手法や技術要素も登録できます。</template>
        </Card>
      </div>

      <div class="col-12 md:col-6">
        <Card>
          <template #title>
            <div class="text-orange-500 text-center">
              <i class="fa-solid fa-chart-line text-6xl mb-3"></i>
              <div>役割と実績を明確にできる</div>
            </div>
          </template>
          <template #content>プロジェクトでの役割と実績を入力できます。対外的に貢献度をアピールできます。</template>
        </Card>
      </div>

      <div class="col-12 md:col-6">
        <Card>
          <template #title>
            <div class="text-blue-500 text-center">
              <i class="fa-solid fa-headset text-6xl mb-3"></i>
              <div>仕事受付</div>
            </div>
          </template>
          <template #content>IT専門家を求める企業からの仕事依頼窓口を設け、システムが依頼内容をまとめ案件にします。</template>
        </Card>
      </div>

      <div class="col-12 md:col-6">
        <Card>
          <template #title>
            <div class="text-cyan-500 text-center">
              <i class="fa-solid fa-circle-nodes text-6xl mb-3"></i>
              <div>優れた検索機能とサジェスト機能</div>
            </div>
          </template>
          <template #content>IT専門家を求める企業の課題を正しく把握し、解決できる専門家を推薦します。</template>
        </Card>
      </div>

      <div class="col-12 md:col-6">
        <Card>
          <template #title>
            <div class="text-pink-500 text-center">
              <i class="fa-solid fa-circle-nodes text-6xl mb-3"></i>
              <div>プロジェクトでつながる本物のビジネスネットワーク</div>
            </div>
          </template>
          <template #content>プロジェクトを共にしたメンバーとつながることができます。一緒に仕事をした人たちとのネットワークが作れます。</template>
        </Card>
      </div>
    </div>
  </div>

  <div class="section bg-blue-50">
    <div class="title text-center">オープン職歴プロフィール例</div>
    <div class="grid">
      <div class="col md:col-6 md:col-offset-3">
        <router-link to="/hirofukami" class="router-link">
          <Card>
            <template #header>
              <div class="text-center">
                <img alt="深海 寛信" src="https://hirofukami.com/images/hirofukami.jpg" style="height: 100px; width: 100px; border-radius: 50px;" class="mt-3">
              </div>
            </template>
            <template #title>
              <div class="text-center">深海 寛信</div>
            </template>
            <template #content>
              <div class="card flex flex-wrap gap-2">
                <Chip label="プロダクトプランナー" icon="pi pi-pencil" class="bg-blue-50 text-sm" />
                <Chip label="プロダクトマネージャー" icon="pi pi-chart-line" class="bg-green-50 text-sm" />
                <Chip label="プロジェクトマネージャー" icon="pi pi-calendar" class="bg-yellow-50 text-sm" />
                <Chip label="ソフトウェアエンジニア" icon="pi pi-code" class="bg-cyan-50 text-sm" />
              </div>
              <div class="text-lg text-left my-3">
                <div class="content">
                  アメリカ法人含め3社を立ち上げ、新規プロダクトを多数リリース。プロダクトマネジメントをしつつ自らもエンジニアとして開発に携わる。
                </div>
                <div class="content">
                  ネットワーク・インフラからWebアプリ、スマホアプリ開発まで幅広く対応する。
                </div>
                <div class="conte">
                  AWSの新機能をいち早く試した実績が評価されAWS入門本を共著。最近の著書は「ひとりスタートアップ」
                </div>
              </div>
            </template>
          </Card>
        </router-link>
      </div>
    </div>
  </div>

  <Divider />

  <div class="section text-center">
    <div class="title">事前登録</div>
    <div class="sbutitile">自分のプロフィールを作成したい方はこちら</div>
    <div class="m-6">
      <Button outlined size="large"
        label="事前登録申請" icon="pi pi-user-plus" severity="info"
        @click="visibleRegistration = true"
      />

      <Dialog v-model:visible="visibleRegistration" modal header="事前登録"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
      >
        <FormRegistratioin />
      </Dialog>
    </div>
  </div>

  <Divider />

  <div class="section text-center">
    <div class="title text-center">ITのプロ・専門家へ依頼したい方は</div>
    <div class="m-6">
      <Button outlined size="large"
        label="仕事依頼・相談をする" icon="pi pi-envelope" rounded severity="danger"
        @click="visibleContact = true"
      />

      <Dialog v-model:visible="visibleContact" modal header="コンタクト"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
      >
        <FormContact />
      </Dialog>
    </div>
  </div>

  <Divider />
</template>

<script setup>
import { ref } from "vue";
import FormRegistratioin from "./FormRegistratioin"
import FormContact from "./FormContact"

const visibleRegistration = ref(false);
const visibleContact = ref(false);
</script>
