const firebaseConfig = {
  apiKey: "AIzaSyDwlDQhkysMxK-qzRHJipk7XilkpaaGiBw",
  authDomain: "joblog-5f4ba.firebaseapp.com",
  projectId: "joblog-5f4ba",
  storageBucket: "joblog-5f4ba.appspot.com",
  messagingSenderId: "456859533105",
  appId: "1:456859533105:web:1a1a089b77dc53cd9ffa10",
  measurementId: "G-L2TJ7SP424"
}

export default firebaseConfig
