import { createRouter, createWebHistory } from 'vue-router'
const BASE_URL = '/'

import Home from './components/Home.vue'
import Profile from './components/Profile.vue'
import ProjectDetail from './components/ProjectDetail.vue'

import db from '@/firebase/firestore.js'
import { doc, getDoc } from "firebase/firestore"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/hirofukami',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/hirofukami/pj/:id',
    name: 'ProjectDetail',
    component: ProjectDetail,
    async beforeEnter(to, from, next) {
      const pjdoc = await getDoc(doc(db, 'projects', to.params.id));
      to.meta.pj = pjdoc;
      next();
    },
    props: (route) => ({ doc: route.meta.pj}),
  },
  // {
  //   path: '/404',
  //   name: '404-NotFound',
  //   component: NotFound,
  // },
  {
    path: '/:pathMatch(.*)',
    redirect: '/'
  },
]

const router = createRouter({
  history: createWebHistory(BASE_URL),  // set BASE_URL
  routes
})

export default router
