<template>
  <div class="md:p-3">

    <div class="mb-5">
      現在はクローズドアルファです。<br>
      事前登録いただければ順番にご招待します。<br>
      登録メールアドレス宛にご連絡いたします。
    </div>

    <form
      action="https://formspree.io/f/moqzplgd"
      method="POST"
    >
      <div class="field">
        <InputText type="name" name="name" required="" placeholder="氏名(ニックネーム可)" class="w-full" />
      </div>

      <div class="field">
        <InputText type="email" name="email" required="" placeholder="メールアドレス" class="w-full" />
      </div>

      <div class="field">
        <InputText type="company" name="company" placeholder="会社名(任意)" class="w-full" />
      </div>

      <div class="field">
        <input
          type="text" name="expectation" list="list"
          placeholder="期待していることを選択もしくは入力してください"
          autocomplete="off" class="p-inputtext p-component w-full"
        >
        <datalist id="list">
          <option v-for="expectation in expectations" :key="expectation" :value="expectation">{{ expectation }}</option>
        </datalist>
      </div>

      <div class="field">
        <Textarea name="message" rows="3" placeholder="開発者へのメッセージがあればどうぞ" class="w-full" />
      </div>

      <Button type="submit" label="送信" severity="primary" />
    </form>

  </div>
</template>

<script setup>
import { ref } from "vue";

const expectations = ref([
  "職歴管理をしたい",
  "実績をPRしたい",
  "一緒に仕事をした人と繋がりたい",
  "今後の転職のアピールをしたい",
  "今後の仕事の依頼を増やしたい",
]);

</script>
