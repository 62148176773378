import { createApp } from 'vue'
import App from './App.vue'

import PrimeVue from 'primevue/config';

import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import "primevue/resources/themes/lara-light-indigo/theme.css"
import "primeflex/primeflex.css"

// primevue components
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import Button from 'primevue/button'
import Card from 'primevue/card'
import Calendar from 'primevue/calendar';
import Chip from 'primevue/chip'
import Chips from 'primevue/chips';
import Column from 'primevue/column'
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown';
import Image from 'primevue/image';
import InputText from 'primevue/inputtext';
import Menubar from 'primevue/menubar'
import Message from 'primevue/message';
import Textarea from 'primevue/textarea';
import TreeTable from 'primevue/treetable'
import Tag from 'primevue/tag'
import Tooltip from 'primevue/tooltip';

// custom css
import './assets/style/common.css';

// router
import router from './router'

const app = createApp(App)

app.use(PrimeVue)
app.component("Avatar", Avatar)
app.component("AvatarGroup", AvatarGroup)
app.component("Button", Button)
app.component("Card", Card)
app.component("Calendar", Calendar)
app.component("Chip", Chip)
app.component("Chips", Chips)
app.component("Column", Column)
app.component("Dialog", Dialog)
app.component("Divider", Divider)
app.component("Dropdown", Dropdown)
app.component("Image", Image)
app.component("InputText", InputText)
app.component("Menubar", Menubar)
app.component("Message", Message)
app.component("Textarea", Textarea)
app.component("TreeTable", TreeTable)
app.component("Tag", Tag)
app.directive('tooltip', Tooltip);

app.use(router)

app.mount('#app')
