<template>

  <div class="p-2">
    <div class="text-4xl font-semibold">
      {{ props.doc.data().title }}
    </div>

    <div class="text-lg my-2">
      期間 {{ range(props.doc.data()) }}
    </div>

    <div v-if="endUserTypes.length > 0" class="my-3">
      <div class="text-2xl font-semibold mt-4 mb-2">企業</div>
      <span class="mr-2" v-for="type in endUserTypes.sort()" :key="type">
        <Chip :label="type" />
      </span>
    </div>

    <div class="text-2xl font-semibold mt-4 mb-2">プロジェクト概要</div>
    <div>
      {{ props.doc.data().description }}
    </div>

    <div class="text-2xl font-semibold mt-4 mb-3">メンバー</div>
    <div class="grid">
      <div v-for="member in members" :key="member" class="md:col-6">
        <Member :doc="member" />
      </div>
    </div>

    <div v-if="props.doc.data().elements_manage && props.doc.data().elements_manage.length > 0" class="my-3">
      <div class="text-2xl font-semibold mb-3">
        マネジメント要素
      </div>
      <span class="mr-2" v-for="element in props.doc.data().elements_manage.sort()" :key="element">
        <Chip :label="element" class="bg-pink-100" />
      </span>
    </div>

    <div v-if="props.doc.data().elements_tech && props.doc.data().elements_tech.length > 0" class="my-3">
      <div class="text-2xl font-semibold mb-3">
        技術要素
      </div>
      <span class="mr-2" v-for="element in props.doc.data().elements_tech.sort()" :key="element">
        <Chip :label="element" class="bg-green-100" />
      </span>
    </div>
  </div>

</template>

<script setup>
import { ref, defineProps, onMounted } from "vue";
import db from '@/firebase/firestore.js'
import { getDoc, query, collection, getDocs } from "firebase/firestore"
import helpers from "@/helpers"
import Member from "./Member"

const props = defineProps({ doc: Object })

const members = ref([])
const endUserTypes = ref([])

const getMembers = async (doc) => {
  const q = query(collection(db, "projects", doc.id, "members"))
  const querySnapshot = await getDocs(q)

  querySnapshot.forEach((doc) => {
    members.value.push(doc)
    console.log("Member data:", doc.id)
  })
}

const getEndUser = async (ref) => {
  const endUserSnap = await getDoc(ref)

  if (endUserSnap.exists() && endUserSnap.data().types) {
    endUserTypes.value = endUserSnap.data().types
  } else {
    console.log("No such document!")
  }
}

const range = (doc) => {
  return helpers.rangeStartEnd(doc)
}

onMounted(() => {
  getMembers(props.doc),
  getEndUser(props.doc.data().end_user_ref)
})
</script>
