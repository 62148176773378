<template>
  <div class="flex align-items-center justify-content-start mb-2">
    <Image v-if="company_image_url" :src="company_image_url" alt="Image"
     height="42"
     class="flex align-items-center justify-content-center"
    />
    <div class="text-3xl flex align-items-center justify-content-center font-semibold ml-2 text-left">
      {{ company.id }}
    </div>
  </div>

  <div v-for="doc in positions" :key="doc.id" class="md:pl-4">
    <Projects
      :position="doc"
      :company="company"
    ></Projects>
  </div>
</template>

<script>
import db from '@/firebase/firestore.js'
import { doc, getDoc, collection, getDocs, query, orderBy } from "firebase/firestore"
import Projects from "./Projects"

export default {
  name: 'Position',
  props: {
    company: {
      type: Object
    },
  },
  data: () => ({
   positions: [],
   company_image_url: '',
  }),
  components: {
    Projects
  },

  async created() {
    const docSnap = await
      getDocs(
        query(
          collection(db, "users", "hirofukami", "companies", this.company.id, "positions"),
          orderBy('start', 'desc')
        )
      )

    docSnap.forEach((doc) => {
      this.positions.push(doc)
      console.log("Document data:", doc.data())
    })

    const companyDocSnap = await getDoc(doc(db, "companies", this.company.id))
    this.company_image_url = companyDocSnap.data().image
  }
}
</script>
