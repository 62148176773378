<template>
  <Menubar class="layout-topbar bg-white z-2 pr-3">
    <template #start>
      <router-link to="/" class="router-link">
        <span class="text-3xl mr-2">JobLog</span>
        <span class="text-xs">(クローズドアルファ)</span>
      </router-link>
    </template>
    <template #end>
<!--       <Button
        icon="pi pi-plus" label="New" severity="info" text
        @click="visibleNew = true"
        class="mr-3"
      /> -->
      <Button
        label="ユーザー登録" severity="info" outlined
        v-tooltip="'自分のプロフィールを作成したい方はこちら'"
        @click="visibleRegistration = true"
      />
    </template>
  </Menubar>

<!--   <Dialog v-model:visible="visibleNew" modal header="プロジェクト作成"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <FormProject @update:visible="handleNewClose" />
  </Dialog>
 -->
  <Dialog v-model:visible="visibleRegistration" modal header="事前登録"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <FormRegistratioin />
  </Dialog>

</template>

<script setup>
import { ref } from "vue";
// import FormProject from "./FormProject"
import FormRegistratioin from "./FormRegistratioin"

// const visibleNew = ref(false);
const visibleRegistration = ref(false);

// const handleNewClose = (value) => {
//   visibleNew.value = value
// }
</script>

<style>
  .layout-topbar {
    width: 100%;
    height: 70px;
    position: fixed !important;
    top: 0;
    border: 0px !important;
  }
</style>
