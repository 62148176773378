function jpYM(date) {
  const month = date.getMonth() + 1
  return date.getFullYear() + '年' + month + '月'
}

function rangeStartEnd(doc) {
  if(doc.start && doc.end) {
    return jpYM(doc.start.toDate()) + "-" + jpYM(doc.end.toDate())
  } else if(doc.start) {
    return jpYM(doc.start.toDate()) + "- 現在"
  } else {
    return null
  }
}

function rangeEndStart(doc) {
  if(doc.start && doc.end) {
    return jpYM(doc.end.toDate()) + "-" + jpYM(doc.start.toDate())
  } else if(doc.start) {
    return "現在 - " + jpYM(doc.start.toDate())
  } else {
    return null
  }
}

export default {
  jpYM,
  rangeStartEnd,
  rangeEndStart
}
